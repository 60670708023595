/* src/styles/buttons.css */
.button {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;
  }
  
  /* Primary button - using brand spruce color */
  .button-brand {
    background-color: #1f513f; /* cdr-color-brand-spruce */
    color: #f9f8f6; /* cdr-color-text-inverse */
    border-color: #1f513f; /* cdr-color-border-button-primary-rest */
  }
  
  .button-brand:hover {
    background-color: #f4f2ed; /* cdr-color-background-button-primary-hover */
    color: #1f513f; /* cdr-color-text-button-primary-hover */
    border-color: #1f513f; /* cdr-color-border-button-primary-hover */
  }
  
  /* Secondary button */
  .button-secondary {
    background-color: #f9f8f6; /* cdr-color-background-button-secondary-rest */
    color: #4e4d49; /* cdr-color-text-button-secondary */
    border-color: #928b80; /* cdr-color-border-button-secondary-rest */
  }
  
  .button-secondary:hover {
    background-color: #f4f2ed; /* cdr-color-background-button-secondary-hover */
    color: #4e4d49; /* cdr-color-text-button-secondary-hover */
    border-color: #4e4d49; /* cdr-color-border-button-secondary-hover */
  }
  
  /* Dark button */
  .button-dark {
    background-color: #4e4d49; /* cdr-color-background-button-dark-rest */
    color: #f9f8f6; /* cdr-color-text-button-dark */
    border-color: #4e4d49; /* cdr-color-border-button-dark-rest */
  }
  
  .button-dark:hover {
    background-color: #edeae3; /* cdr-color-background-button-dark-hover */
    color: #4e4d49; /* cdr-color-text-button-dark-hover */
    border-color: #4e4d49; /* cdr-color-border-button-dark-hover */
  }
  
  /* Sale/Accent button */
  .button-accent {
    background-color: #c7370f; /* cdr-color-background-button-sale-rest */
    color: #ffffff; /* cdr-color-text-button-sale */
    border-color: #c7370f; /* cdr-color-border-button-sale-rest */
  }
  
  .button-accent:hover {
    background-color: #f4f2ed; /* cdr-color-background-button-sale-hover */
    color: #c7370f; /* cdr-color-text-button-sale-hover */
    border-color: #c7370f; /* cdr-color-border-button-sale-hover */
  }
  
  /* Disabled state - applies to all button types */
  .button:disabled {
    background-color: #dcd6cb; /* cdr-color-background-button-default-disabled */
    color: #d1cbbd; /* cdr-color-text-button-default-disabled */
    border-color: #dcd6cb; /* cdr-color-border-button-default-disabled */
    cursor: not-allowed;
  }